import { Metadata } from 'next';
import logoImg from '@public/logo.svg';
import { LAYOUT_OPTIONS } from '@/config/enums';
import logoIconImg from '@public/logo-short.svg';
import { OpenGraph } from 'next/dist/lib/metadata/types/opengraph-types';

enum MODE {
  DARK = 'dark',
  LIGHT = 'light',
}

export const siteConfig = {
  title: 'Allotment - Your partner in wholesale hotel bookings',
  description: `Allotment - Your partner in wholesale hotel bookings.`,
  logo: logoImg,
  icon: logoIconImg,
  mode: MODE.LIGHT,
  layout: LAYOUT_OPTIONS.HYDROGEN,
  // TODO: favicon
};

export const metaObject = (
  title?: string,
  openGraph?: OpenGraph,
  description: string = siteConfig.description
): Metadata => {
  return {
    title: title ? `${title} - Allotment` : siteConfig.title,
    description,
    openGraph: openGraph ?? {
      title: title ? `${title} - Allotment` : title,
      description,
      url: 'https://allotment.sa/',
      siteName: 'Allotment',
      images: {
        url: 'https://utfs.io/f/2ePPpIxtCuRhwFmSN2nqx6AKVgPTN5ve13RpEOQBkZl4GiJH',
        width: 1200,
        height: 630,
      },
      locale: 'en_US',
      type: 'website',
    },
  };
};
