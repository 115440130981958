"use client";

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";
import { CartItem } from "../types/cart";


interface CartContextProps {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (itemId: string) => void;
  clearCart: () => void;
  updateQuantity: (item: CartItem, newQuantity: number) => void;
  updateCartItem: (item: CartItem) => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useState<CartItem[]>([]);

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => [...prevCart, item]);
  };

  const updateCartItem = (updatedItem: CartItem) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.hotelId === updatedItem.hotelId &&
        item.roomId === updatedItem.roomId &&
        item.pricingPeriod.mealPlan === updatedItem.pricingPeriod.mealPlan &&
        item.pricingPeriod.pax === updatedItem.pricingPeriod.pax
          ? updatedItem
          : item
      )
    );
  };

  const removeFromCart = (itemId: string) => {
    setCart((prev) =>
      prev.filter(
        (item) =>
          `${item.hotelId}-${item.roomId}-${item.pricingPeriod.id}` !== itemId
      )
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateQuantity = (item: CartItem, newQuantity: number) => {
    if (newQuantity < 1) {
      removeFromCart(`${item.hotelId}-${item.roomId}-${item.pricingPeriod.id}`);
    } else {
      setCart((prev) =>
        prev.map((cartItem) =>
          cartItem.hotelId === item.hotelId &&
          cartItem.roomId === item.roomId &&
          cartItem.pricingPeriod.id === item.pricingPeriod.id
            ? { ...cartItem, quantity: newQuantity }
            : cartItem
        )
      );
    }
  };

  const value = {
    cart,
    addToCart,
    removeFromCart,
    clearCart,
    updateQuantity,
    updateCartItem,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
