'use client'
import { useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'

export const usePageView = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (pathname) {
      const url = `${pathname}${searchParams?.toString() ? `?${searchParams.toString()}` : ''}`
      if (typeof window !== 'undefined' && 'dataLayer' in window) {
        (window as any).dataLayer.push({
          event: 'pageview',
          page: url,
        })
      }
    }
  }, [pathname, searchParams])
}